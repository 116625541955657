import { Injectable } from '@angular/core';
import { FulltextSearchRequest, FulltextSearchRespones, FulltextSearchResult } from '../api/dynavix/dynavixweb/proto/generated/dynavix/dynavixweb/Fulltext_pb';
import { json } from 'stream/consumers';
import { error } from 'console';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

//const proxyServerURL = "https://api.fleet.dynavix.com/http-server-for-web/"

@Injectable({
  providedIn: 'root'
})

export class FulltextSearchServiceService {

  constructor(private translateService: TranslateService) { }

  public async doSearch(query: string): Promise<FulltextSearchRespones>{
      let payload = {searched_text: query, query_language: this.translateService.currentLang}
      return fetch(environment.proxyServerUrl + "FulltextSearch", {
        method: 'POST',
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(payload),
      }).then(response =>{
        if(response.ok){
          return response.json() as Promise<FulltextSearchRespones>
        }
        throw new Error("Error while handling request, code:" + response.status.toString())
      }).catch(error =>{
          return error
      })

  }
}
