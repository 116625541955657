import { SidenavMenu } from './sidenav-menu.model';

export const sidenavMenuItems = [ 
    new SidenavMenu (1, 'NAV.MONITORING', '/', null, null, false, 0),
    new SidenavMenu (2, 'NAV.NAVIGATION', null, "/products", null, true, 0), 
    // new SidenavMenu (3, 'NAV.SUPPORT', null, "/support", null, true, 0),
    new SidenavMenu (4, 'NAV.ABOUT_US', null, "/about-us", null, true, 0), 
    // new SidenavMenu (5, 'NAV.EV_PORTAL', '/ev-portal', null, null, false, 0), 
    new SidenavMenu (6, 'NAV.NAVIGATION', "/products/dynavix-for-android", null, null, false, 2), 
    //new SidenavMenu (7, 'MENU.DYNAVIX-FOR-IPHONE', "/products/dynavix-for-iphone", null, null, false, 2), 
    //new SidenavMenu (8, 'MENU.DYNAVIX-FOR-MAP', "/products/maps", null, null, false, 2), 

    // new SidenavMenu (9, 'PODPORA PRO ANDROID', "/support/support-for-android", null, null, false, 3), 
    // new SidenavMenu (10, 'PODPORA PRO IPHONE', "/support/support-for-iphone", null, null, false, 3), 
    // new SidenavMenu (11, 'PODPORA PRO PND', "/support/support-for-pnd", null, null, false, 3), 
    // new SidenavMenu (12, 'MANUÁLY', "/support/manuals", null, null, false, 3), 
    // new SidenavMenu (13, 'OPRAVA', "/support/repair", null, null, false, 3), 
    // new SidenavMenu (14, 'DYNAVIX MANAGER', "/support/dynavix-manager", null, null, false, 3), 
    new SidenavMenu (15, 'ABOUTUS.CONTACT', "/about-us", null, null, false, 4), 
    // new SidenavMenu (16, 'Dynavix a.s.', "/about-us/dynavix", null, null, false, 4),
]
