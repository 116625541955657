<span class="search-wrapper" [ngStyle]="{'background-color': isInMiniMenu ? 'white' : 'whitesmoke'}">
    <input [formControl]="searchInput" [ngStyle]="{'background-color': isInMiniMenu ? 'white' : 'whitesmoke'}" class="search-input" matInput type="search" [matAutocomplete]="auto"
        (input)="onSearchInput()" (click)="onSearchInput()">
        <mat-icon style="margin-right: 10px;" matSuffix>search</mat-icon>
</span>
<mat-autocomplete #auto="matAutocomplete">
    <p class="autocomplet-item" *ngFor="let option of this.results"
        (click)="clickResult(option.id, option.url)" [innerHTML]="option.highlighted_text" ></p>

    <mat-option style="visibility: hidden; display: none;">
    </mat-option>
</mat-autocomplete>