import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './basics/acl/admin.guard';

import { RegistrationComponent } from './pages/account/registration/registration.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { RedirectGuard } from './pages/shopping/order-completed/redirect-guard';
import { CookiesComponent } from './pages/main-pages/cookies/cookies.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: '', redirectTo: 'monitoring', pathMatch: 'full' },
            { path: 'monitoring', loadChildren: () => import('./pages/main-pages/home/home.module').then(m => m.HomeModule), data: { title: 'Dynavix - navigační systémy pro mobilní zařízení' } },
            { path: 'cookies', loadChildren: () => import('./pages/main-pages/cookies/cookies.module').then(m => m.CookiesModule), component: CookiesComponent, data: { title: 'Dynavix - informace ke cookies' } },
            { path: 'e-shop', loadChildren: () => import('./pages/main-pages/e-shop/e-shop.module').then(m => m.EShopModule), data: { title: 'Dynavix - e-shop' } },
            // { path: 'e-shop2', loadChildren: () => import('./pages/main-pages/e-shop2/e-shop2.module').then(m => m.EShop2Module), data: { title: 'Dynavix - e-shop2' }, resolve: {data: ScrollToTopResolver} },
            { path: 'ev-portal', loadChildren: () => import('./pages/main-pages/ev-portal/ev-portal.module').then(m => m.EvPortalModule), data: { title: 'Dynavix - EV-portal' } },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Account Settings' } },
            // { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' }, resolve: {data: ScrollToTopResolver} },
            // { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'Wishlist' }, resolve: {data: ScrollToTopResolver} },
            // { path: 'sign-up', component: RegistrationComponent, pathMatch: 'full', resolve: {data: ScrollToTopResolver} },
            { path: 'cart', loadChildren: () => import('./pages/shopping/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } },
            { path: 'completed-order', loadChildren: () => import('./pages/shopping/order-completed/order-completed.module').then(m => m.OrderCompletedModule), data: { breadcrumb: 'Cart' }, canActivate: [RedirectGuard] },
            { path: 'checkout', loadChildren: () => import('./pages/shopping/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
            { path: 'contact', loadChildren: () => import('./pages/footer-pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' } },
            // { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' }, resolve: {data: ScrollToTopResolver} },
            { path: 'log-in', loadChildren: () => import('./pages/account/log-in/log-in.module').then(m => m.LogInModule), data: { breadcrumb: 'Log In ' } },
            { path: 'sign-up', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Sign Up ' }, component: RegistrationComponent },
            // { path: 'sign-up', component:  , data: { breadcrumb: 'Sign Up ' }, resolve: {data: ScrollToTopResolver} },
            // { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Brands' }, resolve: {data: ScrollToTopResolver} },
            { path: 'support', loadChildren: () => import('./pages/main-pages/support/support.module').then(m => m.SupportModule), data: { breadcrumb: 'Support' } },
            { path: 'about-us', loadChildren: () => import('./pages/main-pages/about-us/about-us.module').then(m => m.AboutUsModule), data: { breadcrumb: 'About-us' } },
            { path: 'privacy-policy', loadChildren: () => import('./pages/footer-pages/e-shop-requirement/e-shop-requirement.module').then(m => m.EShopRequirementModule), data: { breadcrumb: 'About-us' } },
            { path: 'e-shop-requirement', loadChildren: () => import('./pages/footer-pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule), data: { breadcrumb: 'Privacy policy' } },
            { path: 'products', loadChildren: () => import('./pages/main-pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'Products' } },
            { path: 'admin', canActivate: [AdminGuard], loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },
        ]
    },
    { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModuleLegacy) },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            scrollPositionRestoration: 'top',
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabled',  // for one load page, without reload
            useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }