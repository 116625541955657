<mat-sidenav-container #content style="background-color: white;">

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()" style="float: right;">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <div style="height: 40px;"></div>
        <div class="divider"></div>
        <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
        <div class="divider"></div>
        <span mat-button class="dropdown" routerLink="/products/e-shop" class="font-awesome special-link" size="lg"
            style="margin-left: 8px; margin-bottom: 10px;">
            <span class="eshop-button">
                <mat-icon class="mat-icon mr-1" svgIcon="shopping-basket"
                    style="align-content: start; display: flex;"></mat-icon>
                Eshop
            </span>
        </span>
        <div style="width: 100%; padding-right: 10px;">
            <app-fulltext-search [isInMiniMenu]="true" (closeMiniMenu)="sidenav.close()"></app-fulltext-search>
        </div>
       
    </mat-sidenav>

    <mat-toolbar color="primary" id="header-toolbar" class="header-menu">
        <!-- <div fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm [@.disabled]="true"> 
                    <button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger" class="search-toggle-btn">
                        <mat-icon class="mat-icon-lg">search</mat-icon>
                    </button>
                    <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="search-dropdown mat-elevation-z8">
                        <ng-template matMenuContent>
                            <form method="get" fxFlex class="search-form">
                                <button mat-raised-button [matMenuTriggerFor]="categories2Menu" #categories2MenuTrigger="matMenuTrigger" type="button" class="mat-elevation-z0 categories text-muted" (click)="stopClickPropagate($event)">{{category?.name}}<mat-icon>arrow_drop_down</mat-icon></button>
                                <mat-menu #categories2Menu="matMenu" [overlapTrigger]="false" xPosition="before" class="categories-dropdown">
                                    <ng-template matMenuContent>
                                        <span (mouseleave)="categories2MenuTrigger.closeMenu()">
                                            <app-category-list [categories]="categories" [categoryParentId]="0" (change)="changeCategory($event)"></app-category-list>
                                        </span>
                                    </ng-template>
                                </mat-menu>
                                <input type="text" placeholder="Type to search..." fxFlex (click)="stopClickPropagate($event)">
                                <button mat-mini-fab (click)="search()" type="button" class="search-btn mat-elevation-z0 text-muted">
                                    <mat-icon>search</mat-icon>
                                </button> 
                            </form>
                        </ng-template>
                    </mat-menu>
                </div>  -->
        <!-- </div>  -->
        <!-- </mat-toolbar-row> -->
    </mat-toolbar>

    <app-menu [changes]="changes" (sidenavToggleEvent)="sidenav.toggle()"></app-menu>

    <div #main class="main">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
    <!-- <app-cookies></app-cookies> -->

    <!-- <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition"
        (click)="scrollToTopAndNavigate()">
        <mat-icon>arrow_upward</mat-icon>
    </div> -->


</mat-sidenav-container>