import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FulltextSearchService } from 'src/app/data/api/dynavix/dynavixweb/proto/generated/dynavix/dynavixweb/Fulltext_pb_service';
import { FulltextSearchServiceService } from 'src/app/data/services/fulltext-search-service.service';

@Component({
  selector: 'app-fulltext-search',
  templateUrl: './fulltext-search.component.html',
  styleUrls: ['./fulltext-search.component.scss']
})
export class FulltextSearchComponent implements OnInit {
  @Output()
  closeMiniMenu: EventEmitter<boolean> = new EventEmitter()
  @Input()
  isInMiniMenu: boolean = false
  searchInput = new FormControl('');
  public results = []

  constructor(private fulltext: FulltextSearchServiceService, private router: Router) { }

  ngOnInit(): void {
  }
  public async onSearchInput() {
    let res: any = await this.fulltext.doSearch(this.searchInput.value)
    this.results = res.search_results
  }

  public clickResult(id: string, url: string) {
    let current_url = this.router.url.split('#')[0]
    this.closeMiniMenu.emit(true)
    if (url != current_url) {
      this.router.navigate([url], { fragment: id, skipLocationChange: false })
    } else {
      let element = document.getElementById(id)
      element.scrollIntoView()
    }
    this.results = []
  }
}
