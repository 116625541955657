import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Product } from '@model/products';
import { TranslateService } from '@ngx-translate/core';
import { AuthOperations } from '@operations-basics/auth.operations';
import { AppSettings } from 'src/app/app.settings';
import { AppService } from '../../../app.service';
import * as serializer from 'proto3-json-serializer';
import { load } from 'protobufjs'
import { FulltextSearchService } from 'src/app/data/api/dynavix/dynavixweb/proto/generated/dynavix/dynavixweb/Fulltext_pb_service';
import { FulltextSearchServiceService } from 'src/app/data/services/fulltext-search-service.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public slides = [
    { title: 'The biggest sale', subtitle: 'Special for today', image: 'assets/images/carousel/banner1.jpg' },
    { title: 'Summer collection', subtitle: 'New Arrivals On Sale', image: 'assets/images/carousel/banner2.jpg' },
    { title: 'The biggest sale', subtitle: 'Special for today', image: 'assets/images/carousel/banner3.jpg' },
    { title: 'Summer collection', subtitle: 'New Arrivals On Sale', image: 'assets/images/carousel/banner4.jpg' },
    { title: 'The biggest sale', subtitle: 'Special for today', image: 'assets/images/carousel/banner5.jpg' }
  ];
  startDemo() {
    window.open(`https://fleet.dynavix.cz/fleet/dashboard`, '_blank')
  }

  public brands = [];
  public featuredProducts: Array<Product>;
  public onSaleProducts: Array<Product>;
  public topRatedProducts: Array<Product>;
  public newArrivalsProducts: Array<Product>;


  constructor(
    public appService: AppService,
    public authService: AuthOperations,
    public translationService: TranslateService,
    public appSettings: AppSettings,
    public translateService: TranslateService,
    private titleService: Title,
    private fulltextService: FulltextSearchServiceService,
  ) { }

  ngOnInit() {
    this.getExamples();

    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('HOME.TITLE').subscribe((data: string) => {
        this.titleService.setTitle(data);
      })
    })
   
  }

  public getExamples(){
    this.brands = [
      {name: "example1", image: "assets/images/dynavix/examples/example1.jpg"},
      {name: "example2", image: "assets/images/dynavix/examples/example2.jpg"},
      {name: "example3", image: "assets/images/dynavix/examples/example3.jpg"},
      {name: "example4", image: "assets/images/dynavix/examples/example4.jpg"},
      {name: "example5", image: "assets/images/dynavix/examples/example5.jpg"},
      {name: "example6", image: "assets/images/dynavix/examples/example6.jpg"},
      {name: "example7", image: "assets/images/dynavix/examples/example7.jpg"},
      {name: "example8", image: "assets/images/dynavix/examples/example8.jpg"},
    ]

  }

  // public getAndroidTitul(): string{
  //   const lang = this.translationService.currentLang
  //   switch(lang) {
  //     case 'cz': {
  //       return "../../assets/images/dynavix/Dynavix-Android-Titul-removebg.png"
  //     }
  //     case 'en': {
  //       return "../../assets/images/dynavix/Dynavix-Android-Titul-en-removebg.png"
  //     }
  //     default: return "../../assets/images/dynavix/Dynavix-Android-Titul-en-removebg.png"
  //   }
  // }
  // public getGooglePlayBadge(): string{
  //   const lang = this.translationService.currentLang
  //   switch(lang) {
  //     case 'cz': {
  //       return "../../assets/images/dynavix/google-play-badge-cs.png"
  //     }
  //     case 'en': {
  //       return "../../assets/images/dynavix/google-play-badge-en.png"
  //     }
  //     default: return "../../assets/images/dynavix/google-play-badge-en.png"
  //   }
  // }
  // public getAppStoreBadge(): string{
  //   const lang = this.translationService.currentLang
  //   switch(lang) {
  //     case 'cz': {
  //       return "../../assets/images/dynavix/appstore-badge-cs.png"
  //     }
  //     case 'en': {
  //       return "../../assets/images/dynavix/appstore-badge-en.png"
  //     }
  //     default: return "../../assets/images/dynavix/appstore-badge-en.png"
  //   }
  // }
  // public getAppGalleryBadge(): string{

  //   const lang = this.translationService.currentLang
  //   switch(lang) {
  //     case 'cz': {
  //       return "../../assets/images/dynavix/appgallery-badge-cs.png"
  //     }
  //     case 'en': {
  //       return "../../assets/images/dynavix/appgallery-badge-en.png"
  //     }
  //     default: return "../../assets/images/dynavix/appgallery-badge-en.png"
  //   }
  // }
  // public getIndexImg(): string{

  //   const lang = this.translationService.currentLang
  //   switch(lang) {
  //     case 'cz': {
  //       return "../../assets/images/dynavix/index.jpg"
  //     }
  //     case 'en': {
  //       return "../../assets/images/dynavix/index-en.jpg"
  //     }
  //     default: return "../../assets/images/dynavix/index-en.jpg"
  //   }
  // }
  // public getTrafficInfoExampleImg(): string{

  //   const lang = this.translationService.currentLang
  //   switch(lang) {
  //     case 'cz': {
  //       return "../../assets/images/dynavix/traffic-info-example-cs.jpg"
  //     }
  //     case 'en': {
  //       return "../../assets/images/dynavix/traffic-info-example-en.jpg"
  //     }
  //     default: return "../../assets/images/dynavix/traffic-info-example-en.jpg"
  //   }
  // }

  // public getInternalInfo() {

  //   let infoRequest = new InternalInfo()
  //   let infoResponse = new InternalInfoResponse()

  //   // console.log(InternalInfoResponse['displayName']);
  //   // console.log(AuthUserDeviceResponse['displayName']);
  //   // console.log(RefreshTokenResponse['displayName']);
  //   // console.log(InternalInfo['displayName']);
  //   // console.log(AuthUserDevice['displayName']);
  //   // console.log(RefreshToken['displayName']);
  //   // console.log(AuthUserDeviceResponse.toString());
  //   // console.log(RefreshTokenResponse.toString());
  //   infoRequest.setUsername("jonak@dynavix.com")
  //   this.httpManager.sendRequest(infoRequest, InternalInfoResponse).subscribe(
  //     (data) => {
  //       console.log(data);
  //     },
  //     err => console.log(err)
  //   )
  // }

  // public forceExpireAT() {

  //   this.authService.forceExpireAT()
  // }



}
