import { UserOperations } from '@operations/user.operations';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Order, PaymentMethod } from '@model/order';
import { Price } from '@model/products';
import { OrderOperations } from '@operations/order.operations';
import { environment } from 'src/environments/environment';

// const SERVER_URL = 'https://account-europe-cz01.dynavix.com/AccountServer/x-protobuf/reply/'
//const proxyServerURL = "https://api.fleet.dynavix.com/http-server-for-web/"

@Injectable({
  providedIn: 'root'
})
export class OrderService implements OrderOperations {

  constructor(private userOp: UserOperations) {
  }

  public getPPLServiceCost(currencyCode: string): Observable<Price> {
    return of(new Price());
    // throw new Error('Method not implemented.');
  }
  public getCashOnDeliveryServiceCost(currencyCode: string): Observable<Price> {
    return of(new Price());
    // throw new Error('Method not implemented.');
  }
  public async placeOrder(order: Order): Promise<string> {
    const token = localStorage.getItem("token") || "";
    return await fetch(environment.proxyServerUrl + "buyfromweb", {
      method: "POST",
      body: JSON.stringify({
        "token": token,
        "chosenProductIds": order.items.map(item => item.product.productId),
        "chosenProductsQuantity": order.items.map(item => item.ammount),
        "currency": "CZK",
        "billingAddress": order.billingAddress,
        "deliveryAddress": order.deliveryAddress,
        "paymentMethod": this.getPaymentMethodId(order.paymentMethod)
      }),
      headers: { "Content-type": "application/json" }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.code === '200'){
        return data.orderNumber;
      }
      throw new Error(data.message)
    })
    //  this.userOp.editBillingAddress(order.billingAddress)
  }

  public getAllOrders(): Observable<Order[]> {
    throw new Error('Method not implemented.');
  }
  public editOrder(order: Order): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public deleteOrder(order: Order): Observable<void> {
    throw new Error('Method not implemented.');
  }

  private getPaymentMethodId(method: PaymentMethod) {
    switch (method) {
      case PaymentMethod.TRANSFER: return 2;
      default: return 0;
    }
  }

}
